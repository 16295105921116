<template>
  <van-collapse v-model="activeNames">
    <van-collapse-item
      v-for="(item, index) in list"
      :key="index"
      :title="item.deviceGroupCode + '-' + item.nodeName"
      :name="index"
    >
      <div
        v-for="(task, idx) in item.vmChannelTasks"
        :key="idx"
        class="rel w-100 p-tb-20 f-ac"
      >
        <div class="abs top left">
          <van-tag type="danger">
            {{ $t(`types[${task.vmChannelChangeType}]`) }}
          </van-tag>
        </div>
        <div class="fs-0 f-y-cc c-blue">
          <span class="fs-22 fw-b">{{ task.channelLabelCode }}</span>
          <span>{{ $t('channelLabelCode') }}</span>
        </div>
        <div class="m-l-10 fg-1 f-y">
          <div class="w-100 f-ac fw-w">
            <span>{{ task.productCode || '' }}</span>
            <van-tag type="primary" class="m-l-10">
              {{ $t('labelChange') }}
            </van-tag>
            <van-tag
              type="primary"
              v-for="(item, index) in task.tags"
              :key="index"
              class="m-l-10"
            >
              {{ item }}
            </van-tag>
            <van-tag
              v-if="task.emptyChange && task.vmChannelChangeType === 1"
              type="primary"
              class="m-l-10"
            >
              {{ $t('labelChange') }}
            </van-tag>
          </div>
          <div class="w-100">{{ task.productName || '' }}</div>
        </div>
        <div
          class="fs-0"
          :class="task.vmChannelProductStatus ? 'c-blue' : 'c-red'"
        >
          {{ $t(`status[${task.vmChannelProductStatus}]`) }}
        </div>
      </div>
    </van-collapse-item>
  </van-collapse>
</template>
<script>
export default {
  data() {
    return {
      activeNames: [],
      list: [],
    }
  },
  created() {
    this.findVmChannelChangeProductTaskDetail()
  },
  methods: {
    async findVmChannelChangeProductTaskDetail() {
      const { data } = await this.$api.findVmChannelChangeProductTaskDetail()
      console.log('findVmChannelChangeProductTaskDetail===>', data)
      this.list = data.map((v) => ({
        ...v,
        isOpen: true,
        vmChannelTasks: v.vmChannelTasks.map((k) => ({
          tags: k.hardwareDesc ? k.hardwareDesc.split('+') : [],
          ...k,
        })),
      }))
      this.activeNames = data.map((v, i) => i)
    },
  },
}
</script>